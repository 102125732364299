<template>
  <section>
    <div class=" tw-mt-32 tw-flex tw-items-center tw-justify-center">
      <img src="@/assets/landingPage/events/payment_received_icon.svg" alt="">
      <div class="tw-ml-10">
        <h3 class="tw-mb-3">Payment received!</h3>
        <p>Thank you! We have sent you an email with your ticket details and receipt. </p>
        <div class="buttons">
          <v-btn depressed rounded dark color="#FF7A01" class="tw-mr-2 email" >
            <a href='mailto:hello@kneek.com' _target="_blank">OPEN EMAIL APP</a></v-btn>
          <v-btn text color="#FF7A01" to="/" >BACK TO HOME</v-btn>


        </div>
      </div>

    </div>
      
  </section>
</template>

<script>
export default {
    name:"PaymentReceivedPage"

}
</script>

<style lang="scss" scoped>
h3{
  font-style: normal;
font-weight: 500;
font-size: 32px;
line-height: 32px;
letter-spacing: -0.03em;
color: #514A41;
}
.buttons ::v-deep .v-btn__content {
  font-style: normal;
font-weight: 700;
font-size: 14px;
line-height: 18px;
letter-spacing: 0.02em;
text-transform: uppercase;
}
p{
  font-style: normal;
font-weight: 400;
font-size: 14px;
line-height: 16px;
color: #514A41;
max-width: 325px;
}
.email{
  a{
    color: #ffffff;
  }
}
</style>